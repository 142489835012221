import React from 'react'
import './Navi.css';
import { Navbar} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { List } from 'react-bootstrap-icons';
import { useState } from 'react';
// import { Button } from 'react-bootstrap';
function Navi() {
  const [isNavOpen,setIsNavOpen] = useState(false);
  const navLinkStyles = ({isActive}) =>{
    return{
      fontWeight: isActive ? '500' : '400',
      color: isActive ? '#000' : '#fff',
      background: isActive ? '#fff' : null,
      padding : isActive ? '0px 10px' : null,
      borderRadius: isActive ? '30px' : null,
      textDecoration: isActive ? 'none' : undefined
    }
  }
  return (
    <>
      {/* <Button variant="primary">Primary</Button> */}
      <Navbar bg="light" expand="lg" className='d-flex justify-content-between' id='firttnav'>
        <div className='left-navs'>
       <h3 className='life-line'>🌹होइहि सोइ</h3>
        </div>
        <div className='center-navs cn-2'>
        <h2>Shri hari bandh Dham Trust</h2>
        </div>
        <div className='additional-nav'>
          {/* Additional item here shyam chinha */}
        </div>
        <div className='right-navs'>
       <h3 className='life-line'> जो राम रचि राखा🌹</h3>
        </div>
</Navbar>
{/* Navigation main part that helps user to route */}
{/* color we have used theme color of website
Tart orange
Hex #FB4D46
RGB 251, 77, 70
CMYK 0, 69, 72, 2
*/}
<nav className={`main-nav hide ${isNavOpen ? 'open' : 'hide'}`}>
  <NavLink style={navLinkStyles} to='/'>Home</NavLink>
  <NavLink style={navLinkStyles} to='Sansthan'>About Sansthan</NavLink>
  <NavLink style={navLinkStyles} to='BandhDham'>hari bandh dham Temple</NavLink> 
  <NavLink style={navLinkStyles} to='Elib'>Events</NavLink>
  <NavLink style={navLinkStyles} to ='Blogs'>Stories</NavLink>
  <NavLink style={navLinkStyles} to ='Ashram'>Our Ashrams</NavLink>
  <div className='new-nav'>
        <h2 className={`cn-3 ${isNavOpen ? 'hideee' : ''}`}>Shri hari bandh Dham Trust</h2>  
 <i className={`list-icon-nav ${isNavOpen ? 'icon-pos' : ''}`}><List onClick={() => setIsNavOpen(!isNavOpen)} /></i>
  </div>
</nav>
    </>
  )
}

export default Navi;
