import React, { useState, useEffect } from 'react';
 import Carousely from './Carousely';
 import WhoHari from './WhoHari';
 import {ImageGal} from './ImageGal.js';
 import styled from 'styled-components';

const Home = () => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    if (showAlert) {
      alert(` भगवान का चिंतन ही सभी प्रकार के कलेशों को हरता है
              जब भगवन को हृदय में बसा लिया जाता है तभी बस हृदय मैं आते हैं।
                        ------जय श्री हरि-------  
      `.toString());
      setShowAlert(false);
    }
  }, [showAlert]);
  const StyledIframe = styled.iframe`
    display: flex;
    border:none;
    margin:0 auto;
    width:90%;
    height:500px;
    padding-bottom:50px;

    @media (max-width: 700px) {
      height: 250px;
    }
`;

const Styledh2 = styled.h2` 
  text-align:center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-bottom:25px;
  font-weight:bold;
  font-size:38px;
`;
const Styleddiv = styled.div `
 width:100%;
 height:auto;
 padding:40px 0px;
 background-color:#FFF1D7;
`;
  return (
    <div>
      
     <Carousely/>
     <WhoHari/>
     <ImageGal/>
     {/* Youtube Video Frame */}
     <Styleddiv>
     <Styledh2>Shri Hari Bandh Dham Complete Tour</Styledh2>
     <StyledIframe src="https://www.youtube.com/embed/Ch3lZIhuSkc?si=gYckuN07ty90887p" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
     </Styleddiv>
    </div>
  )
}

export default Home;
