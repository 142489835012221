import React from 'react'
import styled from 'styled-components';
const Ashitems = (props) => {
    const Styleddiv = styled.div`
      width:75%;
      padding:25px;
      background-color: #FFF1D7;
      margin:30px auto;
      display:flex;
      justify-content: center;
      border-radius:35px;

      @media(max-width:1430px){
       width:80%;
      }
      @media(max-width:1330px){
        width:90%;
       }

       @media (max-width:770px){
        flex-direction:column;
       align-items:center;
       }
    `;
    const Imagediv = styled.div`  
     width:40%;
     img {
          border-radius:100%;
          width:65%;
          height:300px;
     }
     @media (max-width:969px){
        width:30%;
        img{
            width:85%;
            height:250px;
        }
     }
     @media (max-width:770px){
        width:60%;
        margin: 0 auto;
        text-align:center;
        img{
            height:280px;
        }
       }
       @media (max-width:550px){
        width:75%;
        img{
            width:95%;
        }
       }
       @media (max-width:440px){
        width:85%;
       }
    `;
    const Contentdiv = styled.div`
     width:50%;
     position:relative;
     h2,h3 {
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
     }
     h3{
        font-size:20px;
        text-align:center;
     }
     img{
        width:100%;
     }
    a{
      position: absolute;
    right: 40%;
    bottom: 15%;
    }
     @media (max-width:1180px){
        width:60%;
        h3{
            color:red;
        }
     }
     @media (max-width:930px){
        width:70%;
     }

     @media (max-width:770px){
        width:90%;
        margin:15px auto 0 auto;
        text-align:center;
        a{
         right:37%;
         bottom:8%;
        }
     }
     @media (max-width:440px){
        width:100%;
        a{
         right:34%;
         bottom:5%;
        }
     }
    `;
  return (
    <Styleddiv>
          <Imagediv>
            <img src={props.src} alt={props.alt}/>
          </Imagediv>
          <Contentdiv>
             <h2>{props.hd2}</h2>
             <h3>{props.hd3}</h3>
             <img src={props.flute} alt={props.flutex} />
             <a className='btn btn-danger' href={props.Map}>&#x1F4CD;Open Map</a>
          </Contentdiv>
        </Styleddiv>
  )
}

export default Ashitems;
