import './App.css';
import {Routes,Route} from 'react-router-dom';
import Navi from './Components/Navi.js';
import Home from './Components/Home.js';
import Sansthan from './Components/Sansthan';
import Bandhdham from './Components/Bandhdham';
import Elib from './Components/Elib';
import Blogs from './Components/Blogs';
import Ashram from './Components/Ashram';
import Foots from './Components/Foots';
import Contact from './Components/Contact';
function App() {
  return (
    <div className="App">
     <Navi/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='Sansthan' element={<Sansthan/>}/>
        <Route path='BandhDham' element={<Bandhdham/>}/>
        <Route path='Elib' element={<Elib/>}/>
        <Route path='Blogs' element={<Blogs/>}/>
        <Route path='Ashram' element={<Ashram/>}/>
        <Route path='Contact' element={<Contact/>}/>
      </Routes>
       <Foots/>
    </div>
  );
}
export default App;
