import React from 'react'
import './Foots.css';
import { NavLink } from 'react-router-dom';
const Foots = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: "smooth"
    });
  }
  return (
    <>
    <section className='main-footer'> 
     <div className='f1'>
      <h2 className='text-white text-center '>Quick Links</h2>
     <NavLink to='/'onClick={scrollToTop}>Home</NavLink>
  <NavLink to='Sansthan'onClick={scrollToTop}>About Sansthan</NavLink>
  <NavLink to='BandhDham' onClick={scrollToTop}>hari bandh dham Temple</NavLink> 
  <NavLink to='Elib' onClick={scrollToTop}>E-Library</NavLink>
  <NavLink to ='Blogs' onClick={scrollToTop}>Stories</NavLink>
  <NavLink to ='Ashram' onClick={scrollToTop}>Our Ashrams</NavLink>
  <NavLink to='Contact' onClick={scrollToTop}>Contact Us</NavLink>
     </div>
     <div className='f2'>
       <h3 className='text-white'>Follow us On</h3>
       
       <a href='https://instagram.com/haribababandh?igshid=MzMyNGUyNmU2YQ=='>
       <li>
        <img src='https://img.icons8.com/3d-fluency/48/instagram-new.png' alt='instagram'/>
        Instagram
       </li>
       </a>
      <a href='https://youtube.com/@shriharidhambandhtrustsamiti?si=zdWap1Sk7thHUOzZ'>
      <li>
        <img src='https://img.icons8.com/fluency/48/youtube-play.png' alt='youtube'/>
        Youtube
       </li>
      </a>
     </div>
    <div className='f3'>
        <h4 className='text-white'>Contact Office</h4>
           <a href='tel:+919758842050' className='text-white'>+919758842050</a>
    </div>
    </section>
    <h2 className='copywrite'> &copy; 2023 Shri hari Bandh Dham</h2>
    </>
  )
}

export default Foots;
/*
<div class="mapouter">
<div class="gmap_canvas">
<iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Shri hari bandh dham , india&amp;t=&amp;z=20&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
<a href="https://gachanymph.com/">Gacha Nymph APK</a></div><style>.mapouter{position:relative;text-align:right;width:600px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:600px;height:400px;}.gmap_iframe {width:600px!important;height:400px!important;}</style></div>
*/