import React from 'react'
import './WhoHari.css';
const WhoHari = () => {
  return (
    <div className='whohari'>
        <h1 className='text-center'>श्री हरि बाबा कौन थे ?</h1>
       <p>
       श्री हरिबाबा महाराज का जन्म गांव गन्धवाल  जिला होशियारपुर पंजाब में हुआ था। विद्यार्थी जीवन में संत गुरूदेव श्री सच्चिदानन्द  से प्रभावित होकर उन्होंने लाहौर मेडिकल कालेज में एमबीबीएस की पढ़ाई अंन्तिम वर्ष में छोड़कर सन्यास लेकर घर परिवार त्याग दिया और  गंवा के निकट गंगा  के किनारे इस क्षेत्र में आये। यहां की बाढ़ पीड़ित जनता की दयनीय स्थिति को देखकर वह द्रवित हो गये और श्री हरि महाराज  ने बाढ़ से मुक्ति का  संकल्प लिया। सिर पर परात रखकर मिट्टी मदद डालते समय स्वयं श्री हरिबाबा  महाराज घंटा बजाकर हरि कीर्तन करते थे और आसपास गांव के हरि भक्त हरिनाम के साथ बांध बनाने के लिए मिट्टी डालते रहते थे। श्री हरि बाबा महाराज कीर्तन के साथ प्रसाद वितरण करते रहते थे। लोग बताते हैं कि उनके हाथों में ऐसा चमत्कार था कि उनके द्वारा बांटा गया प्रसाद कभी खत्म नहीं हुआ। 
       </p>
<p>
छह माह में बन गया था 36 किमी बांध :

सन 1921 में केवल 6 माह में 36 किलोमीटर लम्बा बांध जन सहयोग से सिर से मिट्टी डालकर तैयार करा दिया। विशाल बांध का निर्माण कराकर खादर के लोगों को गंगा के कहर से जीवन दान दिलाया तथा उनपर अपार कृपा की। तब से आज तक बांध धाम आश्रम पर अखंड हरि कीर्तन चौबीस घंटे चलता रहता है। 
</p>
<p>
मिटटी डालने की परम्परा आज भी कायम : 

श्री हरि महाराज  के जमाने से आज तक बांध धाम पर सर पर रखकर व हाथ से बांध पर मिट्टी डालकर अपने जीवन को धन्य बनाने का सिलसिला आज तक चला आ रहा है। जनपद व प्रदेश तथा देश के आने वाले लाखों श्रद्धालु बांध धाम की परिक्रमा कर बांध पर मिट्टी डालकर अपने को धन्य बनाकर पुण्य कमाते हैं। 
</p>
<p>
गुरुपूजा महोत्सव में पहुंचते हैं लाखों श्रद्धालु : 

श्री हरिबाबा आश्रम पर वैसे तो साल भर श्रद्धालुओं की आवाजाही रहती है मगर गुरुपूजा महोत्सव पर यहां इतना जनसैलाब उमड़ता है कि दूर तक पैर रखने तक की जगह नहीं रहती। देशभर से आने वाले श्रद्धालु हरिबाबा महाराज के दर्शन करने के साथ ही बाध पर मिटटी भी चढ़ाते हैं।
       </p>
       
    </div>
  )
}

export default WhoHari;
