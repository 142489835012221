import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div>
      <div className='contact-me'>
      <div className='tips t-g mt-5'>
       <h4>श्री बुद्धरंजन सिंह -<span className='posst'>अध्यक्ष</span></h4>
     <h5>Mob:- <a href='tel:+919719200132' className='text-white'>+919719200132</a></h5>
    </div>
    <div className='tips t-b'>
       <h4>श्री रमाकांत पाठक -<span className='posst'>मंत्री</span></h4>
       <h5>Mob:-&nbsp;
         <a href='tel:+919758421009' className='text-white'>+919758421009</a>, &nbsp;
         <a href='tel:+919149067209' className='text-white'>+919149067209</a>
         </h5>
    </div>
    <div className='tips t-b mb-5'>
       <h4>श्री विश्वम्भर पाठक -<span className='posst'>उपमंत्री</span></h4>
       {/*  9758971309 */}
       <h5>Mob:- <a href='tel:+919758971309' className='text-white'>+919758971309</a></h5>
    </div>
      </div>
      <div class="mapouter">
<div class="gmap_canvas">
<iframe class="gmap_iframe"  marginheight="0" marginwidth="0" title="harimap" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Shri hari bandh dham , india&amp;t=&amp;z=20&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
<a href="https://gachanymph.com/">Gacha Nymph APK</a></div>
</div>
    </div>
  )
}

export default Contact
