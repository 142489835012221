import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
 width:25%;
 padding:25px 25px;
 background-color:#fff;
 border-radius:50px;
 text-align:center;
 a{
  text-decoration:none;
  background-color:#ee5253;
  color:#fff;
  padding:10px 15px;
  border-radius:10px;
 }
 h2{
  margin-top:20px;
  margin-bottom:25px;
 }
 img{
  width:55%;
  height:150px;
  border-radius:100%;
 }
 @media (max-width:1136px){
  width:30%;
  img{
    width:80%;
  }
 }
 @media (max-width:792px){
 width:60%;
 margin-top:20px;
 }
 @media(max-width:580px){
  width:90%;
 }
`;

const CardStyle = (props) => {
   
  return (
    <>
      
     <StyledCard>
      <img src={props.src} alt='_babaimg'/>
        <h2>{props.heading}
</h2>
<a href={props.url}>Read More</a>
     </StyledCard>
    </>
  )
}
export default CardStyle;
