import React from 'react';
import {ImageGal} from './ImageGal.js';
import styled from 'styled-components';
const StyledDiv = styled.div`
  background-color:#FFF1D7;
  padding:25px 0;
  p{
    width:80%;
    margin:0 auto;
    padding:20px 0 0 0;
    color:#130f40;
  }
  h1{
    text-align:center;
    text-decoration:underline;
  }
`;
const Bandhdham = () => {
  return (
    <>
     <StyledDiv>
      <h1>हरि बांध धाम की संछिप्त कथा</h1>
      <p>
      किसी भी नदी पर बाँध बनाना सरल काम नहीं है। उसमें आधुनिक तकनीक के साथ करोड़ों रुपये और अपार मानव श्रम लगता है; पर श्री हरिबाबा ने केवल हरिनाम-संकीर्तन के बल पर गंगा जी पर बाँध बनाकर लाखों ग्रामवासियों के जन-धन की रक्षा की। 
      </p>
      <p>
      श्री हरिबाबा का जन्म ग्राम मेंगखाला (होशियारपुर, पंजाब) में फाल्गुन शुक्ल 14, वि. सम्वत् 1941 को श्री प्रतापसिंह जी के घर में हुआ था। उनका बचपन का नाम दीवान सिंह था। प्राथमिक शिक्षा के बाद उनके पिता ने उन्हें लाहौर के मैडिकल कालिज में भर्ती करा दिया; पर तब तक वे सांसारिक बन्धन से मुक्त होने का मन बना चुके थे। अतः वे घर छोड़कर भ्रमण में लग गये।
      </p>
      <p>
      उनके गुरु ने उन्हें दीक्षा देकर स्वतः प्रकाश नाम दिया; पर हर समय हरिनाम का संकीर्तन करते रहने के कारण वे ‘हरिबाबा’ के नाम से प्रसिद्ध हो गये। हरिबाबा को गंगा माँ से बहुत प्रेम था। उत्तर प्रदेश के अनूपशहर में वे लम्बे समय तक गंगा के तट पर रहे और वेद-वेदान्तों का गहन अध्ययन किया। उनकी ख्याति सुनकर दूर-दूर से भक्त और सन्त-महात्मा वहाँ आने लगे। 
      </p>
      <p>
      श्री हरिबाबा चैतन्य महाप्रभु की तरह कीर्तन प्रेमी थे। वे गंगातट पर भ्रमण करते हुए भी कीर्तन करते रहते थे। घड़ियाल बजाते हुए जब वे नृत्य करते थे, तो सबके पाँव थिरकने लगते थे। एक बार वर्षाकाल में वे ग्राम गँवा (बदायूँ, उ.प्र.) के पास गंगा तट पर पहुँचे। उस समय गंगा जी का जल समुद्र की तरह दूर-दूर तक फैला हुआ था। किसानों की हजारों एकड़ फसल डूब गयी थी। हजारों पशु भी बाढ़ में बह गये थे। श्री हरिबाबा से लोगों का यह दुख नहीं देखा गया। उन्होंने वहाँ बाँध बनाकर उनकी विपत्ति दूर करने का निश्चय किया।
      </p>
      <p>
      बाबा ने इसके लिए शासन से प्रार्थना की; पर उसने भारी खर्च का बहाना बना दिया। इस पर बाबा ने हरिबोल का उद्घोष किया और स्वयं टोकरी-फावड़ा लेकर जुट गये। यह देखकर गाँव के हजारों नर-नारी भी आ गये। बाबा हरिबोल के कीर्तन से सबका उत्साह बढ़ाने लगे। दूर-दूर तक इस बाँध की चर्चा फैल गयी। बड़े-बड़े सरकारी अधिकारी भी वहाँ आकर श्रमदान करने लगे। अगली वर्षा से पहले 20 कि.मी. लम्बा मोलनपुर बाँध बन कर तैयार हो गया।
      </p>
      <p>
      इस बाँध की विशेषता थी कि इसमें लूले, लंगड़े, कुष्ठरोगी, निर्धन, धनवान, स्वस्थ, बीमार सबने अपना योगदान दिया। धीरे-धीरे बाँध का नाम ही ‘हरिबाबा बाँध’ हो गया। बाँध बनने पर बाबा ने वहाँ विशाल संकीर्तन भवन, मन्दिर और साधु-सन्तों के लिए कुटिया बनवायीं। इससे वहाँ स्थायी रूप से धार्मिक आयोजन होने लगे। बाँध बनने के बाद भी उस क्षेत्र के लोग नशा तथा माँसाहार करते थे। बहुत कहने पर भी जब लोग इससे विरत नहीं हुए, तो बाबा अनशन पर बैठ गये। मजबूर होकर लोगों को इन कुरीतियों को छोड़ना पड़ा। 
      </p>
      <p>
      बाबा जी की सब देवी-देवताओं में आस्था थी। उन्होंने अपने गुरुस्थान होशियारपुर में एक सुन्दर मन्दिर बनवाकर श्री गुरु ग्रन्थ साहब की स्थापना करवायी। अपने अन्तिम समय में वे काफी बीमार हो गये। सब चिकित्सकों ने जवाब दे दिया। यह देखकर आनन्दमयी माँ उन्हें अपने साथ काशी ले गयीं। वहीं श्री हरिबाबा ने चार जनवरी, 1970 को भोर होने से पूर्व 1.40 बजे अपनी नश्वर देह त्याग दी। उस समय भी वहाँ भक्तजन कीर्तन कर रहे थे।
      </p>
     </StyledDiv>
     <ImageGal/>
    </>
  )
}

export default Bandhdham;
