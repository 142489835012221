import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import BabaJi from './assets/babacars1.png';
import Imagess from './assets/babacars2.jpg';
import Aarti from './assets/babacars3.png';
import './Carousely.css';

const Carousely = () => {
  return (
    <>
     <Carousel className='main-carousel'>
      <Carousel.Item interval={2000}>
       <img src={BabaJi} alt='imageji'/>
      </Carousel.Item>
      <Carousel.Item interval={1000}>
      <img src={Imagess} className='image2' alt='dhaamji'/> 
      </Carousel.Item>
      <Carousel.Item>
      <img src={Aarti} alt='Aarti'/>
      </Carousel.Item>
    </Carousel>
    </>
  )
}

export default Carousely;

