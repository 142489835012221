import React from 'react'
import Ashitems from './Ashitems';
import Chdhari from './assets/chdhari.webp';
import Orignalhari from './assets/orignalHari.jpg';
import VrindavanHari from './assets/vrindavanHari.jpg';
import Brili from './assets/Brili.jpg';
import PunjabHari from './assets/PunjabHari.jpg';
import Flute from './assets/ashramflute.png';
const Ashram = () => {
  return (
    <div>
      <Ashitems src={Orignalhari} alt="Image1" hd2="Hari Baba Bandh Dham, Gawan ,243727" hd3="Anoopshahr Rd, Molan Pur Danda, Medpur Danda, UP" flute={Flute} flutex="Imageflute" Map="https://maps.app.goo.gl/s4Vwz48Bxn9oKcnA6"/>

      <Ashitems src={Chdhari} alt="Image2" hd2="Hari Baba Mandir, Chandausi 244412 " hd3="Hathi Wali Gali Near, Jyoti Halwai , Braham Bazzar" flute={Flute} flutex="Imageflute"/>

      <Ashitems src={VrindavanHari} alt="Image3" hd2="Hari Baba Mandir, Vrindavan ,281121 " hd3="Dawanal Kund" flute={Flute} flutex="Imageflute" Map="https://maps.app.goo.gl/DUunQkLJsq2UZ5BH8"/>

      <Ashitems src={Brili} alt="Image4" hd2="Hari Baba Mandir, Bareilly, 243003 " hd3="Unnamed Road, Zakhira,UP" flute={Flute} flutex="Imageflute" Map="https://maps.app.goo.gl/byaxUhA1yj9werhp8"/>

      <Ashitems src={PunjabHari} alt="Image5" hd2="Hari Baba Mandir,Hoshiarpur, 146001 " hd3="Hari Nagar, Hoshiarpur, Punjab" flute={Flute} flutex="Imageflute" Map="https://maps.app.goo.gl/WpUKATxeKgFb5mtT6"/>
    </div>
  )
}

export default Ashram;
