import { ImageViewer } from "react-image-viewer-dv";
import './ImageGal.css';
import Hari1 from './assets/hari1.jpg';
import Hari2 from './assets/hari2.jpg';
import Hari3 from './assets/hari3.jpg';
import Hari4 from './assets/hari4.jpg';
import Hari6 from './assets/hari6.jpg';
import Hari7 from './assets/hari7.jpg';
export const ImageGal = () => {
    return (
      <>
      <h2 className="text-center img-gal-textt">Glimpse From Shri Hari Bandh Dham </h2>
        <div className="main-gal">
           <div className="mg-1">
           <ImageViewer>
            <img src={Hari1}  alt="Your image 1"/>
          </ImageViewer>
           </div>
           <div className="mg-2">
           <ImageViewer>
            <img src={Hari2}  alt="Your image 2"/>
          </ImageViewer>
           </div>
           <div className="mg-3">
           <ImageViewer>
            <img src={Hari3}  alt="Your image 3"/>
          </ImageViewer>
           </div>
           <div className="mg-4">
           <ImageViewer>
            <img src={Hari4}  alt="Your image 4"/>
          </ImageViewer>
           </div>
           <div className="mg-5">
           <ImageViewer>
            <img src={Hari6}  alt="Your image 5"/>
          </ImageViewer>
           </div>
           <div className="mg-6">
           <ImageViewer>
            <img src={Hari7}  alt="Your image 6"/>
          </ImageViewer>
           </div>
        </div>
      </>
    )
}