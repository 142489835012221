import React from 'react'
import './Elib.css';
 import styled from 'styled-components';
import EV from './assets/ev-1.jpg';
import e1 from './assets/e1.jpg';
import e2 from './assets/e2.jpg';

const StyledDiv = styled.div`
display:flex;
justify-content:space-around;
width:100%;
padding:20px 40px;
background-color:#FFF1D7;
@media (max-width:792px){
  flex-direction:column;
  align-items:center;
 }
`;
const Elib = () => {
  return (
    <>
  <div className='upcoming-events'>
        <h1>Upcoming Events</h1>
  </div>
  <section className='up-sec'>
    <div className='e1'>
    <img src={e1} alt='e1 image' />
    </div>
    <div className='e2'>
    <img src={e2} alt='e2 image' />
    </div>
  </section>
  <hr></hr>
  <div className='upcoming-events previous'>
        <h1>Previous Events</h1>
  </div>
    <div className='evnt-1'>
         <img src={EV} alt='event 1 image' />
    </div>
    <hr></hr>
    <div className='ev-vid-1'>
    <iframe width="800" height="500" src="https://www.youtube.com/embed/cG8JedYdsqk?si=Ak8T3eNMQXa-Li_K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </>
  )
}

export default Elib;
