import React from 'react'
import styled from 'styled-components';
 import CardStyle from './CardStyle';
 import Chadari from './assets/chdhari.webp';
 import Babaji from './assets/babaji.jpg';
 import JaiBaba from './assets/jaibaba.jpg';
 const StyledDiv = styled.div`
display:flex;

justify-content:space-around;
width:100%;
padding:20px 40px;
background-color:#FFF1D7;
@media (max-width:792px){
  flex-direction:column;
  align-items:center;
 }
`;
const Blogs = () => {
  return (
    <div>
     <StyledDiv>
      <CardStyle src={Chadari} heading="श्रीहरिबाबाजी आदिखण्ड (वंशपरिचय)" url="https://drive.google.com/file/d/1WnmP2OXg_l1oR1o89gMQE4m6GaZBFDwb/view?usp=sharing"></CardStyle>
      <CardStyle src={Babaji} heading="श्रीहरिबाबाजी जन्म और बाल्यावस्था
" url="https://drive.google.com/file/d/10kON_Xu0Eca5qhsZNw5koEe41YoDsU6q/view?usp=sharing"></CardStyle>
{/* https://drive.google.com/file/d/1Xtx02Mnd5VEvrCwiA4gAy7mG-_aG5fpb/view?usp=sharing */}
<CardStyle src={Chadari} heading="श्रीहरिबाबाजी संन्यास
" url="https://drive.google.com/file/d/1Xtx02Mnd5VEvrCwiA4gAy7mG-_aG5fpb/view?usp=sharing"></CardStyle>
     </StyledDiv>
     {/* Second Row  */}
     <StyledDiv>
     <CardStyle src={JaiBaba} heading="शिवपुरी की अद्भुत बातें " url="https://drive.google.com/file/d/1LoyTSlc3SNdMXCXLgHY3stDNH2VMb4VQ/view?usp=sharing">
</CardStyle>
<CardStyle src={Babaji} heading="बाँध का श्रीगणेश" url="https://drive.google.com/file/d/17Bc9qqDs9EhlOna4c9cJDw3ntlFIWK06/view?usp=sharing">
</CardStyle>
<CardStyle src={JaiBaba} heading="भक्त के यहां खाना खाने गए थे संत हरि बाबा" url="https://drive.google.com/file/d/1d5lI7-z35NI9QR7cOKEYgJmS5UdiTTb9/view?usp=sharing">
</CardStyle>
     </StyledDiv>
    </div>
  )
}

export default Blogs
